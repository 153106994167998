import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-riverside-california.png'
import image0 from "../../images/cities/scale-model-of-the-southern-california-medical-museum-in-riverside-california.png"
import image1 from "../../images/cities/scale-model-of-hidden-valley-nature-center-in-riverside-california.png"
import image2 from "../../images/cities/scale-model-of-riverside-metropolitan-museum-in-riverside-california.png"
import image3 from "../../images/cities/scale-model-of-castle-park-in-riverside-california.png"
import image4 from "../../images/cities/scale-model-of-california-museum-of-photography-in-riverside-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Riverside'
            state='California'
            image={image}
            lat='33.9806005'
            lon='-117.37549419999999'
            attractions={ [{"name": "The Southern California Medical Museum", "vicinity": "218251017, Riverside", "types": ["point_of_interest", "establishment"], "lat": 33.9614178, "lng": -117.39447849999999}, {"name": "Hidden Valley Nature Center", "vicinity": "11401 Arlington Ave, Riverside", "types": ["park", "point_of_interest", "establishment"], "lat": 33.9629534, "lng": -117.49933759999999}, {"name": "Riverside Metropolitan Museum", "vicinity": "3580 Mission Inn Avenue, Riverside", "types": ["museum", "point_of_interest", "establishment"], "lat": 33.981986, "lng": -117.37249500000001}, {"name": "Castle Park", "vicinity": "3500 Polk St, Riverside", "types": ["amusement_park", "point_of_interest", "establishment"], "lat": 33.9029205, "lng": -117.46742130000001}, {"name": "California Museum of Photography", "vicinity": "3824 Main St, Riverside", "types": ["museum", "point_of_interest", "establishment"], "lat": 33.981438, "lng": -117.37433299999998}] }
            attractionImages={ {"The Southern California Medical Museum":image0,"Hidden Valley Nature Center":image1,"Riverside Metropolitan Museum":image2,"Castle Park":image3,"California Museum of Photography":image4,} }
       />);
  }
}